import * as React from "react";
import {
  TextField,
  Button,
  Stack,
  InputAdornment,
  MenuItem,
  InputLabel,
  Checkbox,
  Typography,
  Box,
  Alert,
} from "@mui/material";
import { Save } from "@mui/icons-material";
import ModalUI from "../UI/ModalUI";
import { useDispatch, useSelector } from "react-redux";
import {
  createUnit,
  getAllUnit,
  updateUnit,
} from "../../store/njesiRaportuese";
import {
  createPrice,
  deletePrice,
  getPrice,
  getProduct,
} from "../../store/product";
import { getProductRange } from "../../store/productRange";
import TextFieldDateFilter from "../UI/TextFieldDateFilter";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { id, sq } from "date-fns/locale";
import { getDateFormat } from "../helper/CustomLabel";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";

const ProduktModalEditor = (props) => {
  const dispatch = useDispatch();
  // const { currentPage, searchValueCurrent } = useSelector(
  //   (state) => state.njesiRaportuese
  // );
  const { currentPage, searchValueCurrent, products } = useSelector(
    (state) => state.product
  );
  const user = useSelector((state) => state.auth.user);
  const [productRanges, setProductRanges] = React.useState([]);
  const currencyList = ["ALL", "EUR", "USD"];
  const unitList = ["kw", "mv"];
  const zonaList = [
    "Qarku i Beratit",
    "Qarku i Dibrës",
    "Qarku i Durrësit",
    "Qarku i Elbasanit",
    "Qarku i Fierit",
    "Qarku i Gjirokastrës",
    "Qarku i Korçës",
    "Qarku i Kukësit",
    "Qarku i Lezhës",
    "Qarku i Shkodrës",
    "Qarku i Tiranës",
    "Qarku i Vlorës",
  ];

  const { data, isstatusnew, open } = props;
  const [state, setState] = React.useState({
    productId: "",
    price: "",
    productRangeId: "",
    preferential: false,
    endDate: "",
    startDate: "",
    currency: "ALL",
    fashaMin: 0,
    fashaMax: 0,
    fashaMinUnit: "kw",
    fashaMaxUnit: "kw",
    zona: "",
    comment: "",
    showDisclaimer: false,
  });
  const [errorValues, setErrorValues] = React.useState([]);
  React.useEffect(() => {
    dispatch(getProduct({ page: 0, searchValue: "" }));
  }, [open]);

  const view = !isstatusnew;

  React.useEffect(() => {
    setErrorValues([]);
    setState({
      id: data.id ? data.id : "",
      productId: data.product?.id ? data.product?.id : "",
      price: data.price ? data.price : "",
      productRangeId: data.productRange?.name ? data.productRange?.name : "",
      preferential: data.preferencial ? data.preferencial : false,
      endDate: data.endDate ? data.endDate : "",
      startDate: data.startDate ? data.startDate : "",
      currency: data.currency ? data.currency : "ALL",
      fashaMin: data.fashaMin ? data.fashaMin : 0,
      fashaMax: data.fashaMax ? data.fashaMax : 0,
      fashaMinUnit: data.fashaMinUnit ? data.fashaMinUnit : "kw",
      fashaMaxUnit: data.fashaMaxUnit ? data.fashaMaxUnit : "kw",
      zona: data?.zona ? data?.zona : "",
      status: data?.status ? data?.status : "",
      comment: data?.comment ? data?.comment : "",
      showDisclaimer: data?.showDisclaimer ? data?.showDisclaimer : false,
    });
  }, [data, open]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (name === "preferential") {
      setState({
        ...state,
        preferential: event.target?.checked ? event.target.checked : false,
      });
    } else {
      if (name === "showDisclaimer") {
        setState({
          ...state,
          showDisclaimer: event.target?.checked ? event.target.checked : false,
        });
      } else {
        setState({ ...state, [name]: value ? value : "" });
      }
    }
  };

  const saveUser = () => {
    var initialError = [];
    Object.keys(state).map(function (key, index) {
      if (state[key] !== "" || Object.keys(state[key]).length !== 0) {
      } else {
        initialError.push(key);
      }
    });

    setErrorValues(initialError);

    var initialErrorTemp = [];

    if (state.preferential) {
      initialErrorTemp = initialError.filter(
        (item) => item !== "id" && item !== "preferential" && item !== "comment"
      ); // remove id item
    } else {
      initialErrorTemp = initialError.filter(
        (item) =>
          item !== "id" &&
          item !== "preferential" &&
          item !== "endDate" &&
          item !== "startDate" &&
          item !== "status" &&
          item !== "comment"
      ); // remove item not need
    }

    if (initialErrorTemp.length === 0) {
      if (isstatusnew) {
        // const { id, preferential, ...newPrice } = state;
        if (state.preferential) {
          const { id, preferential, ...newPrice } = state;
          dispatch(createPrice({ price: newPrice }));
        } else {
          const { id, preferential, endDate, startDate, ...newPrice } = state;
          dispatch(createPrice({ price: newPrice }));
        }
        dispatch(getPrice({ page: 0, searchValue: "" }));
      } else {
        const { ...updateUnitTemp } = state;
      }

      dispatch(getPrice({ page: 0, searchValue: "" }));
      props.onClose();
    }
  };

  const returnOffert = () => {
    dispatch(deletePrice({ id: state?.id, page: 0, searchValue: "" }));
    // dispatch(getPrice({ page: 0, searchValue: "" })); // make return offert here

    props.onClose();
  };

  const changeProductId = (e) => {
    setState((prevState) => {
      return {
        ...prevState,
        productId: e.target.value,
      };
    });

    const kot = products.filter((item) => {
      if (item.id === e.target.value) return item.ranges;
    });

    setProductRanges(kot[0].ranges);
  };

  const changeRangeId = (e) => {
    setState((prevState) => {
      return {
        ...prevState,
        productRangeId: e.target.value,
      };
    });
  };

  const changeCurrency = (e) => {
    setState((prevState) => {
      return {
        ...prevState,
        currency: e.target.value,
      };
    });
  };

  const changeFashaUnit = (e) => {
    setState((prevState) => {
      return {
        ...prevState,
        fashaMinUnit: e.target.value,
        fashaMaxUnit: e.target.value,
      };
    });
  };
  const changeZona = (e) => {
    setState((prevState) => {
      return {
        ...prevState,
        zona: e.target.value,
      };
    });
  };
  const checkError = (item) => {
    if (errorValues.find((element) => element === item)) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <ModalUI {...props}>
      {/* <Stack spacing={2}>
        <TextField
          name="name"
          autoComplete="off"
          id="name"
          variant="outlined"
          error={checkError("name")}
          helperText={checkError("name") ? "Emri është i detyruar" : null}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">Emër</InputAdornment>
            ),
          }}
          value={state.name}
          onChange={handleInputChange}
        />
      </Stack> */}
      <Stack spacing={2}>
        {view &&
          user?.role === "ROLE_OPERATOR" &&
          state?.status === "APPROVED" && (
            <Stack sx={{ display: "flex", width: "100%" }}>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "primary.dark",
                  display: "flex",
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
                onClick={returnOffert}
                startIcon={<KeyboardReturnIcon />}
              >
                Tërhiq ofertën
              </Button>
            </Stack>
          )}
        {/* <TextField
          name="name"
          autoComplete="off"
          id="name"
          variant="outlined"
          error={checkError("name")}
          helperText={checkError("name") ? "Emri është i detyruar" : null}
          InputProps={{
            startAdornment: (
              <InputAdornment
                sx={{
                  "&.MuiInputAdornment-root.MuiInputAdornment-root": {
                    minWidth: "0px",
                  },
                }}
                position="start"
              >
                Emër
              </InputAdornment>
            ),
          }}
          value={state.name}
          onChange={handleInputChange}
        /> */}

        <TextField
          labelId="demo-simple-select-label"
          select
          disabled={view}
          name="productId"
          id="productId"
          error={checkError("productId")}
          helperText={
            checkError("productId") ? "Emri i produktit është i detyruar" : null
          }
          value={state.productId ? state.productId : null}
          onChange={changeProductId}
          InputProps={{
            startAdornment: (
              <InputAdornment
                sx={{
                  "&.MuiInputAdornment-root.MuiInputAdornment-root": {
                    minWidth: "110px",
                  },
                }}
                position="start"
              >
                Emër
              </InputAdornment>
            ),
          }}
        >
          {products?.map((item) => {
            return (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            );
          })}
        </TextField>
      </Stack>
      <Stack>
        <TextField
          select={!view}
          disabled={view}
          name="productRangeId"
          autoComplete="off"
          id="productRangeId"
          variant="outlined"
          required="productRangeId"
          error={checkError("productRangeId")}
          helperText={
            checkError("productRangeId")
              ? "Nivel i Tensionit është e detyruar"
              : null
          }
          value={state.productRangeId}
          onChange={changeRangeId}
          sx={{
            "& .MuiInputBase-root.MuiOutlinedInput-root": {
              textAlign: "left",
            },
            "& .MuiInputBase-input.MuiOutlinedInput-input": {
              padding: "12px 14px",
            },
            width: "100%",
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment
                sx={{
                  "&.MuiInputAdornment-root.MuiInputAdornment-root": {
                    minWidth: "110px",
                  },
                }}
                position="start"
              >
                Nivel i Tensionit
              </InputAdornment>
            ),
          }}
        >
          {productRanges.map((option) => (
            <MenuItem key={option?.name} value={option?.id}>
              {option?.name}
            </MenuItem>
          ))}
        </TextField>
      </Stack>
      <Stack spacing={2}>
        <TextField
          name="price"
          disabled={view}
          autoComplete="off"
          id="price"
          type="number"
          variant="outlined"
          error={checkError("price")}
          helperText={checkError("price") ? "Çmimi është i detyruar" : null}
          InputProps={{
            startAdornment: (
              <InputAdornment
                sx={{
                  "&.MuiInputAdornment-root.MuiInputAdornment-root": {
                    minWidth: "110px",
                  },
                }}
                position="start"
              >
                Çmimi
              </InputAdornment>
            ),
          }}
          value={state.price}
          onChange={handleInputChange}
        />
      </Stack>
      <Box
        spacing={1}
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <Stack>
          <TextField
            select
            // disabled={view}
            disabled
            name="fashaMinUnit"
            autoComplete="off"
            id="fashaMinUnit"
            variant="outlined"
            required="fashaMinUnit"
            error={checkError("fashaMinUnit")}
            helperText={
              checkError("fashaMinUnit") ? "Njesia është e detyruar" : null
            }
            value={state.fashaMinUnit}
            onChange={changeFashaUnit}
            sx={{
              "& .MuiInputBase-root.MuiOutlinedInput-root": {
                textAlign: "left",
              },
              "& .MuiInputBase-input.MuiOutlinedInput-input": {
                padding: "12px 14px",
              },
              width: "230px",
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment
                  sx={{
                    "&.MuiInputAdornment-root.MuiInputAdornment-root": {
                      minWidth: "110px",
                    },
                  }}
                  position="start"
                >
                  Njesia
                </InputAdornment>
              ),
            }}
          >
            {unitList.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </Stack>
        <Stack>
          <TextField
            select
            //disabled={view}
            disabled
            name="currency"
            autoComplete="off"
            id="currency"
            variant="outlined"
            required="currency"
            error={checkError("currency")}
            helperText={
              checkError("currency") ? "Valuta është e detyruar" : null
            }
            value={state.currency}
            onChange={changeCurrency}
            sx={{
              "& .MuiInputBase-root.MuiOutlinedInput-root": {
                textAlign: "left",
              },
              "& .MuiInputBase-input.MuiOutlinedInput-input": {
                padding: "12px 14px",
              },
              width: "230px",
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment
                  sx={{
                    "&.MuiInputAdornment-root.MuiInputAdornment-root": {
                      minWidth: "110px",
                    },
                  }}
                  position="start"
                >
                  Valuta
                </InputAdornment>
              ),
            }}
          >
            {currencyList.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </Stack>
      </Box>
      <Box
        spacing={1}
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <Stack>
          <TextField
            name="fashaMin"
            disabled={view}
            autoComplete="off"
            id="fashaMin"
            type="number"
            variant="outlined"
            error={checkError("fashaMin")}
            helperText={
              checkError("fashaMin") ? "Fasha min është e detyruar" : null
            }
            InputProps={{
              startAdornment: (
                <InputAdornment
                  sx={{
                    "&.MuiInputAdornment-root.MuiInputAdornment-root": {
                      minWidth: "90px",
                    },
                  }}
                  position="start"
                >
                  Fasha/Min
                </InputAdornment>
              ),
            }}
            // inputProps={{
            //   sx: {
            //     textAlign: "right",
            //     paddingRight: "4px",
            //   },
            // }}
            sx={{ width: "230px" }}
            value={state.fashaMin}
            onChange={handleInputChange}
          />
        </Stack>
        <Stack>
          <TextField
            name="fashaMax"
            disabled={view}
            autoComplete="off"
            id="fashaMax"
            type="number"
            variant="outlined"
            error={checkError("fashaMax")}
            helperText={
              checkError("fashaMax") ? "Fasha max është e detyruar" : null
            }
            InputProps={{
              startAdornment: (
                <InputAdornment
                  sx={{
                    "&.MuiInputAdornment-root.MuiInputAdornment-root": {
                      minWidth: "90px",
                    },
                  }}
                  position="start"
                >
                  Fasha/Max
                </InputAdornment>
              ),
            }}
            sx={{ width: "230px" }}
            value={state.fashaMax}
            onChange={handleInputChange}
          />
        </Stack>
      </Box>
      <Stack>
        <TextField
          select
          disabled={view}
          name="zona"
          autoComplete="off"
          id="zona"
          variant="outlined"
          required="zona"
          error={checkError("zona")}
          helperText={checkError("zona") ? "Zona është e detyruar" : null}
          value={state.zona}
          onChange={changeZona}
          sx={{
            "& .MuiInputBase-root.MuiOutlinedInput-root": {
              textAlign: "left",
            },
            "& .MuiInputBase-input.MuiOutlinedInput-input": {
              padding: "12px 14px",
            },
            width: "100%",
          }}
          SelectProps={{
            MenuProps: {
              sx: { maxHeight: "300px" },
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment
                sx={{
                  "&.MuiInputAdornment-root.MuiInputAdornment-root": {
                    minWidth: "110px",
                  },
                }}
                position="start"
              >
                Zona
              </InputAdornment>
            ),
          }}
        >
          {zonaList.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
      </Stack>
      <Stack>
        <TextField
          multiline
          maxRows={4}
          name="comment"
          disabled={view}
          autoComplete="off"
          id="comment"
          type="text"
          variant="outlined"
          // error={checkError("comment")}
          // helperText={checkError("comment") ? "Komenti është e detyruar" : null}
          InputProps={{
            startAdornment: (
              <InputAdornment
                sx={{
                  "& .MuiInputBase-root.MuiOutlinedInput-root.MuiOutlinedInput-root":
                    {
                      height: "95px",
                    },
                  "&.MuiInputAdornment-root.MuiInputAdornment-root": {
                    minWidth: "110px",
                  },
                }}
                position="start"
              >
                Shenime
              </InputAdornment>
            ),
          }}
          sx={{
            width: "100%",
            "& .MuiInputBase-root.MuiOutlinedInput-root": {
              minHeight: "120px",
            },
          }}
          value={state.comment}
          onChange={handleInputChange}
        ></TextField>
      </Stack>
      <Stack>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h5" color="primary">
            {view ? "Shfaqur Disclaimer" : "Shfaq Disclaimer"}
          </Typography>
          <Checkbox
            disabled={view}
            name="showDisclaimer"
            id="showDisclaimer"
            // defaultChecked={false}
            checked={state.showDisclaimer}
            onChange={handleInputChange}
            // {...props}
          ></Checkbox>
        </Box>
      </Stack>
      <Stack>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h5" color="primary">
            {view ? "Çmim preferencial" : "Bej çmim preferencial"}
          </Typography>
          <Checkbox
            disabled={view}
            name="preferential"
            id="preferential"
            // defaultChecked={false}
            checked={state.preferential}
            onChange={handleInputChange}
            // {...props}
          ></Checkbox>
        </Box>
      </Stack>
      {state.preferential && (
        <Box
          spacing={2}
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Stack spacing={2}>
            <InputLabel required>Datë Fillimi</InputLabel>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={sq}>
              <DatePicker
                value={state.startDate}
                disabled={view}
                views={["year", "month"]}
                onChange={(newValue) => {
                  var first_date = new Date(
                    newValue.getFullYear(),
                    newValue.getMonth(),
                    1
                  );
                  setState({
                    ...state,
                    startDate: first_date ? getDateFormat(first_date) : "",
                    //dateEnd: "",
                  });
                }}
                PopperProps={{
                  disablePortal: true,
                }}
                renderInput={(params) => (
                  <TextFieldDateFilter
                    maxWidth="200px"
                    inputWidth="120px"
                    error={checkError("startDate")}
                    helperText={
                      checkError("startDate") ? "Data është e detyruar" : null
                    }
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: "muaj viti",
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Stack>
          <Stack spacing={2}>
            <InputLabel required>Datë Mbarimi</InputLabel>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={sq}>
              <DatePicker
                value={state.endDate}
                disabled={view}
                views={["year", "month"]}
                onChange={(newValue) => {
                  var last_date = new Date(
                    newValue.getFullYear(),
                    newValue.getMonth() + 1,
                    0
                  );
                  setState({
                    ...state,
                    endDate: last_date ? getDateFormat(last_date) : "",
                  });
                }}
                PopperProps={{
                  disablePortal: true,
                }}
                renderInput={(params) => (
                  <TextFieldDateFilter
                    maxWidth="200px"
                    inputWidth="120px"
                    {...params}
                    error={checkError("endDate")}
                    helperText={
                      checkError("endDate") ? "Data është e detyruar" : null
                    }
                    inputProps={{
                      ...params.inputProps,
                      placeholder: "muaj viti",
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Stack>
        </Box>
      )}
      {errorValues?.length !== 0 && (
        <Box sx={{ paddingTop: "10px" }}>
          <Alert severity="error">Ploteso te gjitha fushat</Alert>
        </Box>
      )}
      {!view && (
        <Stack sx={{ display: "flex", width: "100%" }}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "primary.dark",
              display: "flex",
              marginTop: "20px",
            }}
            startIcon={<Save />}
            onClick={saveUser}
          >
            {isstatusnew ? "Shto" : "Ndrysho"}
          </Button>
        </Stack>
      )}
    </ModalUI>
  );
};
export default ProduktModalEditor;
